@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100vh;
}

body {
  scroll-behavior: smooth;
  font-size: 87.5%;
  /*14px*/
}

:root {
  --green: #dbffb7;
  /*Light green for status in tables*/
  --gray: #efefef;
  /*Light gray for status in tables*/
  --yellow: #e8b800;
  --blue: #0e226d;
  --lightGray: #f5f4f4;
  --lightgreey: #e7e7e8;
}

.tableHeader {
  background-color: var(--yellow);
  font-weight: 700;
  text-align: left;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.card-headers td {
  font-weight: bold;
}

.card {
  cursor: pointer !important;
}

/*small table font*/
table-sm-font td,
tr {
  font-size: 0.8em;
}

/*Used to limit height of tables and make them scrollable.
Put this class in wrapper div*/
.overflowed {
  max-height: 500px;
  width: 100%;
  overflow: auto;
}

/* To fixed table headers on scroll */
table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  background: #fff;
}

.logo-dashboard-wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.logo-dashboard {
  text-align: center;
}

.logo-dashboard img {
  width: 70%;
}

.logo-dashboard h1.digital-clock {
  font-size: 80px;
  font-weight: bold;
  color: #1c3c86;
}

/*Proveedor selection container*/
.small-img img {
  width: 200px;
  width: 100px;
  margin-right: 1rem;
}

.white-rounded-container {
  display: inline-flex;
  padding: 1rem;
  border-radius: 5px;
  align-items: center;
  background-color: white;
}

.pointer {
  cursor: pointer;
}

.pagination .active .page-link {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}

.pagination .page-link {
  color: var(--yellow);
}

.pagination .page-link:hover {
  color: var(--yellow);
}

.table-fixed-header > thead, th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}
