.report-table {
  border: 1px solid var(--lightgreey);
  background-color: #fff;
  width: 100%;
}

.report-table thead {
  color: var(--blue);
  font-size: 1.2rem;
}

.report-table th {
  padding: 0.1rem;
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--yellow);
  top: 0;
  z-index: 10;
}

.report-table tbody {
  border: 1px solid var(--lightgreey);
  font-size: 0.9rem;
  color: var(--grey);
}

.report-table td {
  padding: 1rem;
  border: 1px solid var(--lightgreey);
}

/*Transacciones list detail*/
.transacc-tbl {
  margin-top: 1rem;
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  text-align: center;
  width: 100%;
}

.transacc-tbl tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.transacc-tbl td {
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  padding: 1px 1rem;
}

@media only screen and (max-width: 600px) {
  .report-table {
    font-size: 0.7rem;
  }

  .report-table th {
    font-size: 0.8rem;
  }

  .report-table td {
    font-size: 0.7rem;
  }

  .transacc-tbl {
    font-size: 0.9rem;
  }

  .transacc-tbl tr {
    display: grid;
    border-top: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);
  }

  .transacc-tbl td {
    border: none;
  }
}

/* New design of the tables */

.table-container {
  height: 100%;
  overflow-y: hidden;
  width: 100%;
}

.table-container .table-header {
  align-items: center;
  background: var(--yellow);
  color: var(--blue);
  display: grid;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 0.5rem 0rem;
  border-bottom: 2px solid var(--blue);
}

.table-header .header-item {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.table-container .table-body {
  overflow-y: auto;
  padding-bottom: 20px;
}

.table-container .table-body .body-content {
  background-color: white;
  border-bottom: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  border-left: 2px solid var(--gray);
  color: black;
  display: grid;
  font-size: 0.8rem;
  font-weight: 500;
  height: auto;
  text-align: center;
}

.table-container .table-body .body-content .body-item {
  justify-content: center;
  align-items: center;
  border-left: 2px solid var(--gray);
  padding: 0.5rem 0rem;
  display: flex;
}

.table-container .table-body .body-content .body-item:first-child {
  border-left: none;
}
