.imagenes-container {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
}

.img-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.img-container label {
  font-size: 20px;
  font-weight: bold;
}

.img-container img {
  width: 500px;
  height: 300px;
  object-fit: contain;
}
