body {
  font-family: "Roboto", sans-serif;
  background-color: #f8fafb;
}

p {
  color: #b3b3b3;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", sans-serif;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a:hover {
  text-decoration: none !important;
}

.content {
  display: flex;
  flex-direction: row;
  width: auto;
  margin: auto auto;
  height: 100vh;
}

.content .content-left {
  width: 100%;
  display: grid;
}

.content .content-left .content-login {
  width: 400px;
  justify-self: center;
  align-self: center;
}

.content .content-left .content-login label {
  color: #9c9c9c;
}

.content .content-left .content-login input {
  height: 50px;
}

.login-header {
  margin-bottom: 100px;
}

.login-header h3 {
  font-size: 35px;
  color: #1c3c86;
}

.login-header label {
  color: #9c9c9c;
}

.content .content-right {
  width: 100%;
  height: 100vh;
  background-color: #f4f7fd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content .content-right .img-logo {
  width: 500px !important;
}

.content .content-right img {
  width: 100%;
}

.btn-login {
  width: 100%;
  background-color: #ffe66d !important;
  color: #14213d !important;
  border-color: #ffe66d !important;
  font-weight: bold !important;
  border-radius: 10px !important;
}

@media (max-width: 576px) {
  .content .content-right {
    display: none;
  }

  .content .content-left {
    padding: 0 20px;
  }

  .content .content-left .content-login {
    width: 100%;
  }
}

/* ******************************** */

h2 {
  font-size: 20px;
}

@media (max-width: 991.98px) {
  .content .bg {
    height: 500px;
  }
}

.content .contents,
.content .bg {
  width: 50%;
}
@media (max-width: 1199.98px) {
  .content .contents,
  .content .bg {
    width: 100%;
  }
}
.content .contents .form-group,
.content .bg .form-group {
  overflow: hidden;
  margin-bottom: 0;
  padding: 15px 15px;
  border-bottom: none;
  position: relative;
  background: #edf2f5;
  border-bottom: 1px solid #e6edf1;
}
.content .contents .form-group label,
.content .bg .form-group label {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.content .contents .form-group input,
.content .bg .form-group input {
  background: transparent;
}
.content .contents .form-group.first,
.content .bg .form-group.first {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.content .contents .form-group.last,
.content .bg .form-group.last {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.content .contents .form-group label,
.content .bg .form-group label {
  font-size: 12px;
  display: block;
  margin-bottom: 0;
  color: #b3b3b3;
}
.content .contents .form-group.focus,
.content .bg .form-group.focus {
  background: #fff;
}
.content .contents .form-group.field--not-empty label,
.content .bg .form-group.field--not-empty label {
  margin-top: -20px;
}
.content .contents .form-control,
.content .bg .form-control {
  border: none;
  padding: 0;
  font-size: 20px;
  border-radius: 0;
}
.content .contents .form-control:active,
.content .contents .form-control:focus,
.content .bg .form-control:active,
.content .bg .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.content .bg {
  background-size: cover;
  background-position: center;
}

.content a {
  color: #888;
  text-decoration: underline;
}

.content .btn {
  height: 54px;
  padding-left: 30px;
  padding-right: 30px;
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
}
.control .caption {
  position: relative;
  top: 0.2rem;
  color: #888;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 4px;
}

.control--radio .control__indicator {
  border-radius: 50%;
}

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}

.control input:checked ~ .control__indicator {
  background: #6c63ff;
}

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #847dff;
}

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.9;
  pointer-events: none;
}

.control__indicator:after {
  font-family: "icomoon";
  content: "\e5ca";
  position: absolute;
  display: none;
  font-size: 16px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 3;
}

/*overlay div*/
