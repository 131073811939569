.btn-sales {
    background-color: #E8B800 !important;
    border-color: #E8B800 !important;
    color: black !important;
    font-weight: 500 !important;
    margin-right: 15px;
}

.btn-save-sales {
    background-color: #E8B800 !important;
    border-color: #E8B800 !important;
    color: black !important;
    font-weight: 500 !important;

}