.the-loader-container {
    width: 100vw;
    height: 100vh;
    background-color: #00000050;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.the-loader {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}