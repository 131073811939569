.control-panel{
  padding: 5px 40px;
  border: 2px solid rgb(170, 169, 169);
  border-radius: 20px;
  background-color: #162C50 !important;
  color: white;
}

.control-panel button {
  background-color: #457B9D !important;
  color: white;
}

.control-panel .right-top{
  display: flex;
  justify-content: center;
}

.control-panel .right{
  display: flex;
  flex-flow: row;
}

#btnExcelRecargas-wrapper{
  justify-content: center;
}

#btnExcelRecargas-wrapper img{
  margin: 15px;
}

.recarga-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto !important;
  padding: 30px;
  border: 2px solid rgb(218, 215, 215);
  border-radius: 20px;
  width: 100%;
  background-color: #457B9D !important;
}
.recarga-wrapper img{
  max-width: 100%;
}
