.btn-primary {
  background-color: #ffe66d !important;
  color: #14213d !important;
  border-color: #ffe66d !important;
  font-weight: bold !important;
  height: 50px;
  font-size: 18px !important;
  box-shadow: 5px 18px 15px -8px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.btn-create-asist {
  background-color: #ffe66d !important;
  color: #14213d !important;
  border-color: #ffe66d !important;
  font-weight: bold !important;
  height: 50px;
  font-size: 18px !important;
  /* box-shadow: 5px 18px 15px -8px rgba(0,0,0,0.40); */
  border-radius: 10px;
}

.btn-create {
  width: 330px;
}

.estado {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  margin: 5px !important;
}

.table-font {
  font-size: 18px;
}

div.alert-message {
  border: 4px solid #ffe66d;
  padding: 8px;
}

.alert-title {
  text-align: center;
  text-transform: uppercase;
  color: #14274c;
}

div.divactions {
  margin: 0 auto;
}

.form-check-input-table-save {
  position: relative !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

span.minutosAsist {
  font-size: 22px;
}

.assistence-table {
  border: 1px solid var(--lightgreey);
  background-color: #fff;
  width: 100%;
}

.assistence-table thead {
  color: var(--blue);
  font-size: 1.2rem;
  background-color: var(--yellow);
}

.assistence-table th {
  padding: 0.1rem;
  position: relative;
  top: 0;
  z-index: 10;
}

.assistence-table tbody {
  border: 1px solid var(--lightgreey);
  font-size: 0.9rem;
  color: var(--grey);
}

.assistence-table td {
  padding: 1rem;
  border: 1px solid var(--lightgreey);
  font-size: 1.2rem;
}

table .table-font-50 tr td {
  font-size: 12px !important;
}

.tableFixHead {
  overflow: auto;
  height: 100px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.btn-tagexpress {
  background-color: var(--yellow) !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 20px rgba(231, 231, 234, 0.62) !important;
  width: 100%;
}

.btn-tagexpress-danger {
  background-color: red !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 20px rgba(231, 231, 234, 0.62) !important;
  width: 100%;
}

.btn-tagexpress-success {
  background-color: #198754 !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 20px rgba(231, 231, 234, 0.62) !important;
  width: 100%;
}

.btn-tagexpress-secondary {
  background-color: #6c757d !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 20px rgba(231, 231, 234, 0.62) !important;
  width: 100%;
}

.btn-tagexpress-light {
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 20px rgba(231, 231, 234, 0.62) !important;
  width: 100%;
}

.rating {
  display: flex;
  justify-content: center;
  gap: 2px;
}

.rating svg {
  color: #c7c7c7;
  cursor: pointer;
}

.ratingdiv:hover svg {
  color: rgb(212, 180, 0);
  fill: rgb(212, 180, 0);
}
.rating div {
  width: 120px;
}
.rating svg:hover ~ svg {
  color: #c7c7c7;
}

.rating .clickedstar {
  color: rgb(212, 180, 0);
}
