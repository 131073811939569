.header-result {
  display: flex;
  justify-content: space-around;
}

.header-result .header-item {
  padding: 2px;
}

.table-report {
  overflow-y: scroll;
  height: 100%;
}

@media (min-width: 768px) {
  .table-report {
    height: calc(100vh - 320px) !important;
  }
}

.accordion-report .accordion-button {
  background-color: var(--yellow) !important;
  color: #000 !important;
}
