.error-view {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.error-white-section {
  background-color: #f6ffcd;
  width: 90%;
  text-align: center;
  border-radius: 2rem;
  border: 3px solid bisque;
  padding: 1rem;
}

.error-number {
  color: #000;
  font-size: 120px;
  font-weight: 600;
  line-height: 130px;
}

.home-link {
  color: var(--yellow);
}