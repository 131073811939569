#vehiculos-wrapper {
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}

.vehiculo-btn {
  margin: 5px;
  padding: 7px;
  cursor: pointer;
  background-color: var(--yellow);
  color: #fff;
  border: 1px solid var(--yellow);
  border-radius: 5px;
}

.mantenim-wrapper {
  padding: 15px;
  border: 1px solid #dadada;
  border-radius: 5px;
  max-height: 500px;
  overflow-y: auto;
  background-color: var(--yellow);
}

.mantenim-container {
  -webkit-box-shadow: 8px 8px 12px -9px rgba(0, 0, 0, 0.71) !important;
  box-shadow: 8px 8px 12px -9px rgba(0, 0, 0, 0.71) !important;
}

.mantenim-first {
  background-color: #162C50 !important;
  color: #fff;
  -webkit-box-shadow: 8px 8px 12px -9px rgba(0, 0, 0, 0.71) !important;
  box-shadow: 8px 8px 12px -9px rgba(0, 0, 0, 0.71) !important;
}

.btn-agregar {
  display: inline-block;
  margin-left: 20px;
}

.trash-icon {
  float: right;
}